/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

/*
*{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
*/
.table tbody tr {
  text-transform: uppercase
}

#navbarSupportedContent app-select-field-search label,
#navbarSupportedContent form-field-error {
  display: none;
}

.btn {
  box-shadow: none !important;
  outline: none !important;
}

.swal2-cancel {
  margin-left: 5px;
}

.hide {
  display: none !important;
}

.form-nova-senha label {
  display: block !important;
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 13pt;
}

.form-nova-senha .swal2-input {
  margin-top: 2px !important;
}

.form-nova-senha .info-senha {
  font-size: 12pt;
  margin-top: 5px;
  font-weight: bold;
}


bs-datepicker-container {
  background: rgb(222, 226, 230);
  padding: 5px;
}

.form-label.required:after {
  content: " *";
  color: red;
}

.titulo_seccao {
  margin-top: 5px;
  border-bottom: 2px solid #C2D033
}

.box-current-empresa label {
  color: #2C75BA !important;
  padding-left: 4px;
  display: block;
}

.form-check-label:hover {
  cursor: pointer;
}